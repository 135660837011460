export default {
  meta_title: "TREBEL: Descarga música sin costo y escúchala sin internet",
  home: 'home',
  careers: 'careers',
  aboutUs: 'about-us',
  partners: 'partners',
  qr_text: 'Scan to Download TREBEL app',
  support_your_artists: "Support your artists",
  support_description: "We compensate artists fairly and work with the world's largest record labels to bring their music to you legally.",
  enjoy_music: "Enjoy your favorite music",
  enjoy_music_description: "Find music from the world's biggest record labels and major independent distributors.",
  sponsor_experience: "Sponsor the TREBEL experience",
  sponsor_btn: "Find out how",
  get_full_experience: "get the full experience",
  take_control_of_music: "take control of your music",
  pass_gives_benefits: "TREBEL PASS gives you the benefits for a luxurious musical experience without tying you to a subscription.",
  improve_experience: "Improve your experience and enjoy even more your favorite music",
  with_pass: "With TREBEL Pass you can enjoy:",
  choose_your_pass: "Choose your pass",
  payment_method: 'Payment Method',
  no_ads: "No ads",
  speed_downloads: "Speed downloads",
  all_your_playlists: "All your Playlists",
  listen_while_downloading: "Listen while downloading",
  unlimited_coins: "Unlimited coins",
  how_it_works: "How it Works",
  days: "days",
  phone_number: "Phone Number",
  email: "Email",
  personal_data:
    "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.",
  download_the_app: 'Download the App',
  about_us: {
    main_title: 'We are not just another music service.',
    description_title: 'We Are Changing The Tune Completely.',
    description_1: 'TREBEL was launched at the end of 2018 with a mission to create an exceptional music experience for millions of people around the world. Users have quickly adopted our service, confirming our belief that various socioeconomic factors (such as income, access to a credit card, the cost of data connectivity, etc.) make subscription services inaccessible to most people around the world.',
    description_2: 'Today, almost 90% of the 6.5 billion smartphone users around the world do not pay for the music they consume. As this number grows, we believe the next big opportunity within the music industry will come from improving the experience and monetization of these billions of music users.',
    description_3: "At TREBEL, we've partnered with the biggest global advertisers and international music and media companies to create the first legal and licensed service that offers music without the need for an internet connection, all thanks to the sponsorship of the brands.",
    download_and_listen: 'Download it and start listening to your music your way.',
    find_out: "Find out how"
  },
  map_description: "Our team works from Mexico, Armenia, Indonesia, Colombia, Brazil and the United States to bring music to the entire world.",
  our_team: "Our team",
  board_members: "Board Members",
  board_advisors: "Board Advisors",
  team_description: "We brought together industry experts, technologists, and entrepreneurs to build a company that is solving one of the music industry's biggest problems.",
  careers_page: {
    main_title: "Join the team.",
    work_with_us: {
        title: 'Work with us',
        description1: '<b> TREBEL is the fastest growing music service in Latin America </b> and the first entertainment soonicorn in the region.',
        description2: "<b> TREBEL is not just another music service </b>, TREBEL is the only app where users don't pay and still can download songs, listen without internet, and support artists through a business model sponsored by brands.",
        btn_text: 'view openings'
    },
    remote: {
      heading: 'We are a remote-first company',
      description: "Why hire the best person for the job in a 20-mile radius, when you can hire the best person for the job, period?"
    },
    interested_in_joining: "Interested in joining?",
    interested_description: "We are always looking for exceptional talent for our team. Send us your resume and we will contact you when there is a suitable position for your profile.",
    our_values: {
      title: 'Our Values',
      description: 'Our values ​​are the foundation of everything we do at TREBEL.',
      blocks: {
        blockOne: {
          title: "People matter.",
          description: "Employees at all levels are shown trust and respect and get recognized and rewarded for taking risks, creating new opportunities, and taking initiatives. We learn from our mistakes and the team as a whole celebrates the satisfaction of noble effort and ambition."
        },
        blockTwo: {
          title: "Always keep pushing.",
          description: "We have grand pursuits. Tune out the noise, the naysayers, and those who don’t understand. If your conviction or personal risk tolerance was never tested, our startup will challenge just about everything you thought you knew. Stay the course and we will accomplish what others think is impossible."
        },
        blockThree: {
          title: "Seek out challenges that are nearly impossible.",
          description: "If something were easy, other people would be doing it. We look for tough-to-solve opportunities and then endeavor with great enthusiasm and vigor to innovate and demonstrate what can be."
        },
        blockForth: {
          title: "Have fun.",
          description: "Life is short and at Trebel you should love what you do every day and strike that right balance between your work life and personal life. Trebel isn’t about foosball tables or being part of a cult, but we should all enjoy our life and the people we are with."
        }
      }
    }
  },
  partners_page: {
    main_title: "Be part of our world.",
    desc_title: 'Connect with your customers through a unique digital experience.',
    desc_description: "Tell your brand story through full-screen ads, video and immersive experiences to connect with millions of fans who are discovering a new way to listen to music.",
    btn_text: 'get in touch',
    icons: {
      safe: {
        title: "Brand safe",
        description: "The best content in the world."
      },
      view: {
        title: "Top Viewability for Video",
        description: "Immersive, high-engagement experience."
      },
      humans: {
        title: "Real Humans, Fraud Free",
        description: "Real humans watch and interact with your brand."
      },
      stream: {
        title: "100% In-Stream",
        description: "Flexible units targeted at the right audience."
      },
      sound: {
        title: "100% Sound On",
        description: "Be heard with ads that sound (and look) beautiful."
      },
      skip: {
        title: "100% Unskippable",
        description: "Be seen, no questions asked."
      }
    },
    our_audience: "Our audience is waiting to meet you",
    our_audience_desc: "Our mission is to provide millions of users with access to the largest library of content, free of charge, thanks to brand sponsorship. We can help you ensure that your message is delivered to the right audience in the midst of a unique experience in the world.",
    have_content: "Have content? Partner with us!",
    have_content_desc: "Whether you’re a label owner, podcast network or music distribution company, we’d love to add you to our growing list of partners and help you expand your audience.",
    have_content_btn: "DROP US A LINE",
    ready_to_join: "Are you ready to join us?",
    join_btn_text: "Get in touch"
  },
  send_us_resume: "SEND US YOUR RESUME",
  downloadPage: 'Play your music with or without an internet connection',
  meta: {
    main: {
      title: "Free music downloader app for Android and iOS | TREBEL Music",
      description: "Listen to millions of songs for free with TREBEL music downloader app for Android and iOS. Download today for mobile mp3 and music to start listening offline!"
    },
    about: {
      title: "About TREBEL Music",
      description: "TREBEL Music is a global leader in free legal music access with no internet or payment required for both Android and iOS. Learn our story and meet our team."
    },
    career: {
      title: "TREBEL Music Careers: Job Openings",
      description: "TREBEL's mission is to create an exceptional offline music experience for free to users worldwide. To get there, we need your talent. Are you in?"
    },
    partners: {
      title: "Join Our Millions of Music Fans as a TREBEL Partner",
      description: "Become a part of TREBEL\'s exceptional music experience for millions of users worldwide. Join as one of our brand and content partners today!"
    }
  },
  on_our_playlist: "On your playlist...",
  streaks: {
    title: `How Music Can Boost Your Mood,     Health, and Smarts`,
    march5: "MARCH 5, 2024",
    minRead: "5 MIN READ",
    wellness: "WELLNESS",
    content: {
      intro: `Have you ever wondered why that favorite song of yours has such a powerful impact on your mood? Well, science has got your back! In a nutshell, listening to music everyday  is not just a vibe—it's a health kick, a mood lifter, and even a brain booster.`,
      intro2: `Speaking of boosters, listening to music everyday in TREBEL can earn you boosters to download your songs faster.`,
      qrTitle: 'Scan the QR With your Phone and Start a Streak',
      qrButton: 'Start a Streak!'
    },
    list: {
      title: "Science Backs It Up!",
      subtitle: "In 2009, archaeologists found a 40,000-year-old flute, the oldest known musical instrument, proving our love affair with music has deep roots. But, how does it affect us today?.",
      elements: {
        first: {
          title: '1. Music Connects Us',
          sub1: "Ever feel that special connection at a concert? It's real! Music unites us socially, from anthems at sports events to sending love songs to your crush.",
          sub2: "Evolutionary scientists even suggest it's rooted in our tree-dwelling ancestry, when our ancestors “sang” to create social bonds and form their tribes. "
        },
        second: {
          title: "2. Music Makes us Happier",
          sub1: "Music can change your brain chemistry—triggering pleasure, reducing stress, and fostering connections. It is also a great tool to regulate emotions and process feelings. ",
          sub2: "Feeling anxious? Studies show music can calm nerves, helping us ground ourselves and live in the moment."
        },
        third: {
          title: "3. Music is Fuel for your Body",
          sub1: "Music makes your heart race—in a good way! It can alter your heart rate, blood pressure, and breath rate. If you are headed to the gym, listening to your favorite playlist improves your mood, efficiency, and endurance.",
          sub2: "Plus, dancing to your favorite tunes? That's a cardio session in disguise!"
        },
        forth: {
          title: "4. Music is Brain Food",
          sub1: "Want to supercharge your brain? Doctors at Johns Hopkins say listening to music does the trick, activating brain areas seen in MRI scans.",
          sub2: "Classical tunes not only make you feel fancy but also boost memory. They help you remember words better and perform tasks faster. Not into classical music? Seek out songs with drums and percussions to get the same effect."
        }
      }
    },
    takeaway: {
      title: "The Takeway",
      sub1: "In a nutshell, music is a superpower that boosts memory, lightens your mood, reduces anxiety and depression, fights fatigue, improves pain response, and enhances your workout.",
      sub2: "If you want to maximize these benefits, commit to completing a streak in TREBEL, not only will you be getting the built-in advantages of listening to music everyday, but you will unlock rewards like boosters to make your downloads faster."
    }
  }
}

export default function ({ route, params, redirect, app }) {
  if(route.path.includes('/features') && app.i18n.locale !== 'id'){
    app.i18n.locale === 'en' ?  redirect('/') : redirect('/es/inicio')
  }

  if(route.path.includes('/get-in-touch') && app.i18n.locale !== 'en'){
    app.i18n.locale === 'es' ? redirect('/es/inicio') : redirect('/id/home')
  }

  const forbittenRoutes = ['landing', 'about-us', 'careers', 'partners', 'inicio','nuestra-historia', 'empleos', 'business', 'download', 'descargar'];
  const rName = route.path.split('/')[2]
  const brPaths = ['terms', 'privacy']

  if(route.path.includes('/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users') && app.i18n.locale !== 'en'){
    app.i18n.locale = 'en'
    redirect('/')
  }

  if(app.i18n.locale === 'br')  {
    if(rName && !brPaths.includes(rName)){
      redirect('/id/home')
    }
    if(route.path === '/br') redirect('/id/home')
  }

  if(app.i18n.locale === 'id' && rName && forbittenRoutes.includes(rName))  {
    redirect('/id/home')
  }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8a8e76aa = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _6abdf3f8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _90cab3d8 = () => interopDefault(import('../pages/bug-program/index.vue' /* webpackChunkName: "pages/bug-program/index" */))
const _b6f8d5c4 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _57a09856 = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _f8631d6c = () => interopDefault(import('../pages/features/index.vue' /* webpackChunkName: "pages/features/index" */))
const _1c17e00a = () => interopDefault(import('../pages/get-in-touch/index.vue' /* webpackChunkName: "pages/get-in-touch/index" */))
const _5c83d800 = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _03d71838 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _66ce8bb8 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _7d0d4d1e = () => interopDefault(import('../pages/streaks/index.vue' /* webpackChunkName: "pages/streaks/index" */))
const _93504c5c = () => interopDefault(import('../pages/terms-of-service/index.vue' /* webpackChunkName: "pages/terms-of-service/index" */))
const _1e81bdc8 = () => interopDefault(import('../pages/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users/index.vue' /* webpackChunkName: "pages/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _8a8e76aa,
    name: "about-us___en"
  }, {
    path: "/br",
    component: _6abdf3f8,
    name: "index___br"
  }, {
    path: "/bug-program",
    component: _90cab3d8,
    name: "bug-program___en"
  }, {
    path: "/careers",
    component: _b6f8d5c4,
    name: "careers___en"
  }, {
    path: "/download",
    component: _57a09856,
    name: "download___en"
  }, {
    path: "/features",
    component: _f8631d6c,
    name: "features___en"
  }, {
    path: "/get-in-touch",
    component: _1c17e00a,
    name: "get-in-touch___en"
  }, {
    path: "/landing",
    component: _5c83d800,
    name: "landing___en"
  }, {
    path: "/partners",
    component: _03d71838,
    name: "partners___en"
  }, {
    path: "/privacy-policy",
    component: _66ce8bb8,
    name: "privacy-policy___en"
  }, {
    path: "/streaks",
    component: _7d0d4d1e,
    name: "streaks___en"
  }, {
    path: "/terms-of-service",
    component: _93504c5c,
    name: "terms-of-service___en"
  }, {
    path: "/br/about-us",
    component: _8a8e76aa,
    name: "about-us___br"
  }, {
    path: "/br/bug-program",
    component: _90cab3d8,
    name: "bug-program___br"
  }, {
    path: "/br/careers",
    component: _b6f8d5c4,
    name: "careers___br"
  }, {
    path: "/br/download",
    component: _57a09856,
    name: "download___br"
  }, {
    path: "/br/features",
    component: _f8631d6c,
    name: "features___br"
  }, {
    path: "/br/get-in-touch",
    component: _1c17e00a,
    name: "get-in-touch___br"
  }, {
    path: "/br/landing",
    component: _5c83d800,
    name: "landing___br"
  }, {
    path: "/br/partners",
    component: _03d71838,
    name: "partners___br"
  }, {
    path: "/br/privacy",
    component: _66ce8bb8,
    name: "privacy-policy___br"
  }, {
    path: "/br/streaks",
    component: _7d0d4d1e,
    name: "streaks___br"
  }, {
    path: "/br/terms",
    component: _93504c5c,
    name: "terms-of-service___br"
  }, {
    path: "/es/bug-program",
    component: _90cab3d8,
    name: "bug-program___es"
  }, {
    path: "/es/business",
    component: _03d71838,
    name: "partners___es"
  }, {
    path: "/es/descargar",
    component: _57a09856,
    name: "download___es"
  }, {
    path: "/es/empleos",
    component: _b6f8d5c4,
    name: "careers___es"
  }, {
    path: "/es/features",
    component: _f8631d6c,
    name: "features___es"
  }, {
    path: "/es/get-in-touch",
    component: _1c17e00a,
    name: "get-in-touch___es"
  }, {
    path: "/es/inicio",
    component: _6abdf3f8,
    name: "index___es"
  }, {
    path: "/es/landing",
    component: _5c83d800,
    name: "landing___es"
  }, {
    path: "/es/politica-de-privacidad",
    component: _66ce8bb8,
    name: "privacy-policy___es"
  }, {
    path: "/es/sobre-nosotros",
    component: _8a8e76aa,
    name: "about-us___es"
  }, {
    path: "/es/streaks",
    component: _7d0d4d1e,
    name: "streaks___es"
  }, {
    path: "/es/terminos",
    component: _93504c5c,
    name: "terms-of-service___es"
  }, {
    path: "/id/about-us",
    component: _8a8e76aa,
    name: "about-us___id"
  }, {
    path: "/id/bug-program",
    component: _90cab3d8,
    name: "bug-program___id"
  }, {
    path: "/id/careers",
    component: _b6f8d5c4,
    name: "careers___id"
  }, {
    path: "/id/download",
    component: _57a09856,
    name: "download___id"
  }, {
    path: "/id/features",
    component: _f8631d6c,
    name: "features___id"
  }, {
    path: "/id/get-in-touch",
    component: _1c17e00a,
    name: "get-in-touch___id"
  }, {
    path: "/id/home",
    component: _6abdf3f8,
    name: "index___id"
  }, {
    path: "/id/landing",
    component: _5c83d800,
    name: "landing___id"
  }, {
    path: "/id/partners",
    component: _03d71838,
    name: "partners___id"
  }, {
    path: "/id/privacy-policy",
    component: _66ce8bb8,
    name: "privacy-policy___id"
  }, {
    path: "/id/streaks",
    component: _7d0d4d1e,
    name: "streaks___id"
  }, {
    path: "/id/terms-of-service",
    component: _93504c5c,
    name: "terms-of-service___id"
  }, {
    path: "/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users",
    component: _1e81bdc8,
    name: "post-trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users___en"
  }, {
    path: "/br/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users",
    component: _1e81bdc8,
    name: "post-trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users___br"
  }, {
    path: "/es/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users",
    component: _1e81bdc8,
    name: "post-trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users___es"
  }, {
    path: "/id/post/trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users",
    component: _1e81bdc8,
    name: "post-trebel-music-inks-strategic-deal-and-announces-increase-in-monthly-active-users___id"
  }, {
    path: "/",
    component: _6abdf3f8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

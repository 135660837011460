export default [
    {
        id: 'companyName',
        name: 'companyName',
        title: null,
        maxLength: 256,
        label: 'Nombre de la compañía *',
        optional: false,
        type: 'text',
    },
    {
        id: 'yourName',
        name: 'name',
        title: null,
        maxLength: 256,
        label: 'Tu nombre *',
        optional: false,
        type: 'text',
    },
    {
        id: "email",
        name: "email",
        title: null,
        label: 'Email *',
        optional: false,
        type: 'email',
    },
    {
        id: "phoneNumber",
        name: "phoneNumber",
        title: null,
        label: 'Número de teléfono *',
        optional: false,
        type: 'text',
    },
    {
        id: "leaveYourMessage",
        name: "message",
        maxLength: 10000,
        label: 'Deja tu mensaje *',
        optional: false,
        type: 'textarea',
    },

]
